if (document.readyState !== 'loading') {
	ready();
} else {
	document.addEventListener('DOMContentLoaded', ready);
}

function ready() {
	// Append a close button for mobile
	const sideNav = document.querySelector('#SideNav');
	const close = document.createElement('span');
	close.classList.add('close');
	close.classList.add('navbar-toggle');
	close.innerText = 'X';
	sideNav.prepend(close); 

	// After Animation Ends
	const html = document.querySelector('#SideNav');
	html.addEventListener('animationend', toggleMobileMenuAfterAnimation);
	/*html.addEventListener('webkitAnimationEnd', toggleMobileMenuAfterAnimation, { once: true });
	html.addEventListener('MSAnimationEnd', toggleMobileMenuAfterAnimation, { once: true });
	html.addEventListener('oAnimationEnd', toggleMobileMenuAfterAnimation, { once: true });*/

	// Kill Bootstrap 4 dropdown toggle
	const dropdownButtons = document.querySelectorAll('.dropdown-toggle');
	for (let i = 0; i < dropdownButtons.length; i++) {
		dropdownButtons[i].removeAttribute('data-toggle');
		dropdownButtons[i].removeAttribute('aria-expanded');
	}

	// Mobile Menu Toggle
	const menuButtons = document.querySelectorAll('.navbar-toggle');
	for (let i = 0; i < menuButtons.length; i++) {
		menuButtons[i].addEventListener('click', toggleMobileMenu);
	}

	// Submenu Toggle
	//const menusWithSubAnchor = document.querySelectorAll('#SideNav > ul > li.dropdown > a');
	const menusWithSubAnchor = document.querySelectorAll('#SideNav > li.dropdown > a');
	for (let i = 0; i < menusWithSubAnchor.length; i++) {
		menusWithSubAnchor[i].addEventListener('click', toggleMenu);
	}
}

const toggleMobileMenu: { (e: MouseEvent): void } = (e: MouseEvent) => {
	//let button = e.target as Element;
	//alert('slide');
	e.stopPropagation();
	const html = document.querySelector('html');
	const isOpen = html.classList.contains('layer1');
	html.classList.toggle('slideMenu', !isOpen);
	html.classList.toggle('slideMenuBack', isOpen);
}

const toggleMobileMenuAfterAnimation: { (e: AnimationEvent): void } = (e: AnimationEvent) => {
	const html = document.querySelector('html');
	html.classList.toggle('layer1');
	html.classList.remove('slideMenu');
	html.classList.remove('slideMenuBack');
}

const toggleMenu: { (e: MouseEvent): void } = (e: MouseEvent) => {
	e.preventDefault();
	e.stopPropagation();
	let menuLink = e.target as Element;
	let menu = menuLink.parentNode as Element;
	let children = menuLink.nextElementSibling as HTMLElement;
	if (!menu.classList.contains('open')) {
		// Close all other open items
		//const openMenus = document.querySelectorAll('#SideNav > ul > li.dropdown.open');
		const openMenus = document.querySelectorAll('#SideNav > li.dropdown.open');
		for (let i = 0; i < openMenus.length; i++) {
			openMenus[i].classList.remove('open');
		}
		menu.classList.add('open');
	} else {
		document.location.href = (menuLink as HTMLAnchorElement).href;
	}
}